import React from 'react';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Menu
} from 'antd';
import {
  ToolOutlined,
  CustomerServiceOutlined
} from '@ant-design/icons';
import {
  faCog,
  faUniversity,
  faUser,
  faUsers,
  faHome,
  faSignOut,
  faFileAlt,
  faUpload,
  faHistory,
  faBus,
  faReceipt,
  faFile,
  faFolderOpen
} from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  userAtom,
  tokenAtom,
  userPrivilagesAtom,
  userOperatorsAtom,
  menuSideBarAtom
} from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';

function AuthenticatedSideBar() {
  const history = useHistory();
  const { t } = useTranslation();
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setIsMenuOpen = useSetRecoilState(menuSideBarAtom);
  const userPrivilages = useRecoilValue(userPrivilagesAtom);
  const userOperators = useRecoilValue(userOperatorsAtom);

  return (
    <Menu
      className="sidebar bg-transparent text-bodyText flex flex-col flex-1 border-0 overflow-auto divide-y divide-gray-100 scrollbar-thin"
      mode="inline"
      forceSubMenuRender
      inlineCollapsed={false}
    >
      <NavLink
        exact
        to="/profile"
        activeClassName="bg-gray-100"
        onClick={() => setIsMenuOpen(false)}
        className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
      >
        {/* <FontAwesomeIcon icon={faUser} />
        <div>{t('profile')}</div> */}
        <Avatar src={user?.avatar} />
        <div>{user?.completeName}</div>
      </NavLink>
      <Menu.Item
        onClick={() => setIsMenuOpen(false)}
        key="home"
        className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
        icon={<FontAwesomeIcon icon={faHome} />}
      >
        {t('home')}
        <Link to="/home" />
      </Menu.Item>

      {userPrivilages?.hasGlobalPrivilages && (
        <Menu.SubMenu key="globaladminservices" icon={<ToolOutlined />} title={t('global_admin_services')}>
          <Menu.Item
            onClick={() => setIsMenuOpen(false)}
            key="serverconfig"
            className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
            icon={<FontAwesomeIcon icon={faCog} />}
          >
            {t('server_config')}
            <Link to="/serverconfig" />
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {userPrivilages?.hasAdminPrivilages && (
        <Menu.SubMenu key="adminservices" icon={<ToolOutlined />} title={t('admin_services')}>
          <Menu.Item
            onClick={() => setIsMenuOpen(false)}
            key="fileprocesses"
            className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
            icon={<FontAwesomeIcon icon={faFileAlt} />}
          >
            {t('storage_file_processes')}
            <Link to="/fileprocesses" />
          </Menu.Item>
          <Menu.Item
            onClick={() => setIsMenuOpen(false)}
            key="operators"
            className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
            icon={<FontAwesomeIcon icon={faUniversity} />}
          >
            {t('operators')}
            <Link to="/operators" />
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {userPrivilages?.hasCustomerSupportPrivilages && (
        <Menu.SubMenu key="customerservices" icon={<CustomerServiceOutlined />} title={t('customer_service')}>
          <Menu.Item
            onClick={() => setIsMenuOpen(false)}
            key="users"
            className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
            icon={<FontAwesomeIcon icon={faUsers} />}
          >
            {t('users')}
            <Link to="/users" />
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {(userPrivilages?.hasGlobalPrivilages ||
      _.filter(userOperators, (op) => op.id === envConfig.TransdevOperatorId).length > 0) && (
        <Menu.SubMenu
          key="transdevservices"
          title={
            <div className="ml-6">Transdev</div>
          }
        >
          <Menu.Item
            onClick={() => setIsMenuOpen(false)}
            key="transdevinvoice"
            className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
            icon={<FontAwesomeIcon icon={faReceipt} />}
          >
            {t('invoices')}
            <Link to="/transdev/invoice" />
          </Menu.Item>
          <Menu.Item
            onClick={() => setIsMenuOpen(false)}
            key="transdevfarebox"
            className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
            icon={<FontAwesomeIcon icon={faFile} />}
          >
            {t('farebox_collection_submissions')}
            <Link to="/transdev/farebox" />
          </Menu.Item>
          <Menu.Item
            onClick={() => setIsMenuOpen(false)}
            key="transdevincidentals"
            className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
            icon={<FontAwesomeIcon icon={faFolderOpen} />}
          >
            {t('incidentals')}
            <Link to="/transdev/incidentals" />
          </Menu.Item>
          {(userPrivilages?.hasGlobalPrivilages || (userPrivilages?.hasOperatorAdminPrivilages &&
            _.filter(userOperators, (op) => op.id === envConfig.TransdevOperatorId).length > 0)) && (
              <Menu.Item
                onClick={() => setIsMenuOpen(false)}
                key="transdevlaw123"
                className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                icon={<FontAwesomeIcon icon={faFile} />}
              >
                {t('law_123')}
                <Link to="/transdev/law123" />
              </Menu.Item>
          )}
        </Menu.SubMenu>
      )}

      {(userPrivilages?.hasGlobalPrivilages || (userPrivilages?.hasOperatorAdminPrivilages &&
      _.filter(userOperators, (op) => op.id === envConfig.AmaOperatorId).length > 0) ||
      (userPrivilages?.hasRouteValidationPrivilages && _.filter(userOperators,
        (op) => op.id === envConfig.AmaOperatorId).length > 0)) && (
        <Menu.SubMenu
          key="amaservices"
          title={
            <div className="ml-6">AMA</div>
          }
        >
          {(userPrivilages?.hasGlobalPrivilages || (userPrivilages?.hasOperatorAdminPrivilages &&
          _.filter(userOperators, (op) => op.id === envConfig.AmaOperatorId).length > 0)) && (
            <>
              <Menu.Item
                onClick={() => setIsMenuOpen(false)}
                key="amaholidays"
                className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                icon={<FontAwesomeIcon icon={faBus} />}
              >
                {t('holidays')}
                <Link to="/ama/holidays" />
              </Menu.Item>
            </>
          )}
          {(userPrivilages?.hasGlobalPrivilages || (userPrivilages?.hasRouteValidationPrivilages &&
          _.filter(userOperators, (op) => op.id === envConfig.AmaOperatorId).length > 0)) && (
            <>
              <Menu.Item
                onClick={() => setIsMenuOpen(false)}
                key="amaroutevalidation"
                className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                icon={<FontAwesomeIcon icon={faBus} />}
              >
                {t('route_validation')}
                <Link to="/ama/routevalidation" />
              </Menu.Item>
            </>
          )}    
        </Menu.SubMenu>
      )}

      <Menu.Item
        onClick={() => setIsMenuOpen(false)}
        key="document_upload"
        className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
        icon={<FontAwesomeIcon icon={faUpload} />}
      >
        {t('document_upload')}
        <Link to="/document-upload" />
      </Menu.Item>
      <Menu.Item
        onClick={() => setIsMenuOpen(false)}
        key="upload-history"
        className="px-4 py-3 flex items-center space-x-3 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
        icon={<FontAwesomeIcon icon={faHistory} />}
      >
        {t('upload_history')}
        <Link to="/upload-history" />
      </Menu.Item>

      <div className="mx-4 md:mx-0 flex items-center justify-between px-12 py-2 mt-4 rounded-full bg-rose-500 hover:bg-rose-600 hover:scale-125 transition duration-200 ease-in-out">
        <button
          type="button"
          className="flex text-sm items-center font-medium text-white h-full w-24 ring-0 outline-none focus:ring-0 focus:outline-none"
          onClick={() => {
            const promise = new Promise(function (resolve, reject) {
              setIsMenuOpen(false);
              window.localStorage.removeItem(`${envConfig.StoragePrefix}-user`);
              window.localStorage.removeItem(`${envConfig.StoragePrefix}-token`);
              setToken(null);
              resolve();
            });
            promise.then(() => {
              setUser(null);
            })
            .then(() => {
              history.push('/');
            });
          }}
        >
          <FontAwesomeIcon className="mr-2" icon={faSignOut} />
          {t('sign_out')}
        </button>
      </div>
    </Menu>
  );
}

export default AuthenticatedSideBar;
