import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table, DatePicker, Divider } from 'antd';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-duotone-svg-icons';
import { useJsonToCsv } from 'react-json-csv';
import MainPage from '../../shared-components/main-page/MainPage';
import { Spinner } from '../../shared-components/Spinner';
import { H1, H3 } from '../../shared-components/typography/Title';
import Button from '../../shared-components/buttons/Button';
import { thousand, toCurrency, percentage } from '../../../functions';
import api from '../../../api/api';
import { notificationsAtom, userPrivilagesAtom } from '../../../atoms/Atoms';
import SummaryCard from './shared-components/SummaryCard';
import PopconfirmButton from '../../shared-components/buttons/PopconfirmButton';

function Transdevlaw123() {
  const { saveAsCsv } = useJsonToCsv();
  const { t, i18n } = useTranslation();
  const userPrivilages = useRecoilValue(userPrivilagesAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loadingExportInvoice, setLoadingExportInvoice] = useState(false);

  const tableColumns = [
    {
      title: t('subsystem'),
      key: 'subsystem',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{row.subsystem}</span>
      ),
    },
    {
      title: t('route'),
      key: 'route',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{row.routeId}</span>
      ),
    },
    {
      title: t('weekday_average_trip_count'),
      key: 'weekdayAverageTripCount',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{thousand(row.weekdayAverageTripCount)}</span>
      ),
    },
    {
      title: t('weekend_average_trip_count'),
      key: 'weekendAverageTripCount',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{thousand(row.weekendAverageTripCount)}</span>
      ),
    }
  ];
  
  function getData() {
    setLoading(true);
    const startDate = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ');
    const endDate = dayjs(selectedDate).add(1, 'year').add(-1, 'second').format('YYYY-MM-DDTHH:mm:ssZ');
    api.get(`transdev/123law?startDate=${startDate}&endDate=${endDate}`)
    .then((response) => {
      setLoading(false);
      setInitialLoad(false);
      if (response.data.success) {
        setData(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoading(false);
      setInitialLoad(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function exportPdf(type, title) {
    setLoadingExportInvoice(true);
    const startDate = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ');
    const endDate = dayjs(selectedDate).add(1, 'month').add(-1, 'minute').format('YYYY-MM-DDTHH:mm:ssZ');
    api.get(`transdev/invoices/pdf?type=${type}&startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' })
      .then((response) => {
        setLoadingExportInvoice(false);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${title}_${selectedDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error(error);
        setLoadingExportInvoice(false);
      });
  }

  useEffect(() => {
    setSelectedDate(dayjs().format('YYYY'));
  }, []);

  useEffect(() => {
    if (selectedDate) {
      getData();
    }
  }, [selectedDate]);

  return (
    <MainPage>
      <H1 text={`Transdev - ${t('law_123')}`} />
      {initialLoad && (
        <div className="w-full h-full flex items-center">
          <Spinner className="mx-auto text-accentColor" big />
        </div>
      )}
      {!initialLoad && (
        <>
          <div className="flex float-right space-x-2">
            <DatePicker
              disabled={loading}
              picker="year"
            //   minDate={dayjs().add(-1, 'year').format('YYYY')}
            //   maxDate={dayjs().format('YYYY')}
              className="w-full h-11 mt-2 md:ml-2 lg:ml-0 xl:ml-2 bg-transparent"
              value={dayjs(selectedDate)}
              onChange={(obj) => {
                setSelectedDate(obj.format('YYYY'));
              }}
              format="YYYY"
            />
            <Button
              className="bg-primaryColor mb-4 px-2 mt-2"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              text={t('refresh')}
              loading={loading}
              onClick={() => getData()}
            />
          </div>
          <Divider className="mt-6 pt-4" orientation="left">{t('daily_trips_per_route')}</Divider>
          <Table
            bordered
            locale={{
              emptyText: t('no_data')
            }}
            loading={loading}
            pagination={false}
            rowKey="id"
            dataSource={data}
            columns={tableColumns}
          />
        </>
      )}
    </MainPage>
  );
}

export default Transdevlaw123;
