import Home from '../components/pages/home/Home';
import NotFound from '../components/pages/404/NotFound';
import Login from '../components/pages/login/Login';
import Recover from '../components/pages/forgotpassword/Recover';
import Reset from '../components/pages/forgotpassword/Reset';
import Register from '../components/pages/register/Register';
import UpdatePass from '../components/pages/updatepass/UpdatePass';
import Profile from '../components/pages/profile/Profile';
import Users from '../components/pages/users/Users';
import Operators from '../components/pages/operators/Operators';
import SafetyForm from '../components/pages/safety-form/SafetyForm';
import DocumentUpload from '../components/pages/document-upload/DocumentUpload';
import ServerConfig from '../components/pages/server-config/ServerConfig';
import UploadHistory from '../components/pages/document-upload/UploadHistory';
import AmaRouteValidation from '../components/pages/ama/AmaRouteValidation';
import AmaHolidays from '../components/pages/ama/AmaHolidays';
import TransdevInvoices from '../components/pages/transdev/TransdevInvoices';
import FareboxCollectionSubmissions from '../components/pages/transdev/FareboxCollectionSubmissions';
import TransdevIncidentals from '../components/pages/transdev/TransdevIncidentals';
import TransdevLaw123 from '../components/pages/transdev/TransdevLaw123';
import FileProcesses from '../components/pages/fileprocesses/FileProcesses';

const routes = [
    {
      id: 'login',
      name: 'login',
      path: '/',
      exact: true,
      protected: false,
      component: Login,
    },
    {
      id: 'recover',
      name: 'recover',
      path: '/recover',
      exact: true,
      protected: false,
      component: Recover,
    },
    {
      id: 'reset',
      name: 'reset',
      path: '/reset:id/:token',
      exact: true,
      protected: false,
      component: Reset,
    },
    {
      id: 'register',
      name: 'register',
      path: '/register',
      exact: true,
      protected: false,
      component: Register,
    },
    {
      id: 'home',
      name: 'home',
      path: '/home',
      exact: true,
      protected: false,
      component: Home,
    },
    {
      id: 'profile',
      name: 'profile',
      path: '/profile',
      exact: true,
      protected: false,
      component: Profile,
    },
    {
      id: 'serverconfig',
      name: 'serverconfig',
      path: '/serverconfig',
      exact: true,
      protected: false,
      component: ServerConfig,
    },
    {
      id: 'operators',
      name: 'operators',
      path: '/operators',
      exact: true,
      protected: false,
      component: Operators,
    },
    {
      id: 'users',
      name: 'users',
      path: '/users',
      exact: true,
      protected: false,
      component: Users,
    },
    {
      id: 'document-upload',
      name: 'document-upload',
      path: '/document-upload',
      exact: true,
      protected: false,
      component: DocumentUpload,
    },
    {
      id: 'upload-history',
      name: 'upload-history',
      path: '/upload-history',
      exact: true,
      protected: false,
      component: UploadHistory,
    },
    {
      id: 'safety-form',
      name: 'safety-form',
      path: '/safety-form',
      exact: true,
      protected: false,
      component: SafetyForm,
    },
    {
      id: 'updatepass',
      name: 'updatepass',
      path: '/updatepass',
      exact: true,
      protected: false,
      component: UpdatePass,
    },
    {
      id: 'amaroutevalidation',
      name: 'amaroutevalidation',
      path: '/ama/routevalidation',
      exact: true,
      protected: false,
      component: AmaRouteValidation,
    },
    {
      id: 'amaholidays',
      name: 'amaholidays',
      path: '/ama/holidays',
      exact: true,
      protected: false,
      component: AmaHolidays,
    },
    {
      id: 'transdevinvoices',
      name: 'transdevinvoices',
      path: '/transdev/invoice',
      exact: true,
      protected: false,
      component: TransdevInvoices,
    },
    {
      id: 'transdevfareboxcollection',
      name: 'transdevfareboxcollection',
      path: '/transdev/farebox',
      exact: true,
      protected: false,
      component: FareboxCollectionSubmissions
    },
    {
      id: 'transdevIncidentals',
      name: 'transdevIncidentals',
      path: '/transdev/incidentals',
      exact: true,
      protected: false,
      component: TransdevIncidentals
    },
    {
      id: 'transdevlaw123',
      name: 'transdevlaw123',
      path: '/transdev/law123',
      exact: true,
      protected: false,
      component: TransdevLaw123
    },
    {
      id: 'fileprocesses',
      name: 'fileprocesses',
      path: '/fileprocesses',
      exact: true,
      protected: false,
      component: FileProcesses,
    },
    /* Error route */
    {
      id: '404',
      name: 'Not Found',
      path: '*',
      exact: true,
      protected: false,
      component: NotFound,
    },
]; 

export default routes;
